import React, { useState, useRef, useEffect } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

const FileUploadComponent = ({
  predefinedWidth = 170 * 3,
  predefinedHeight = 100 * 3,
  onFileUpload,
  title = "Upload Photo",
  square = false,
}) => {
  const [imageData, setImageData] = useState(null); // Store image data URL
  const [croppedImage, setCroppedImage] = useState(null); // Store cropped image URL
  const [isCropping, setIsCropping] = useState(true); // Flag to check if cropping is allowed
  const cropperRef = useRef(null); // Reference to the div holding the image
  const imgRef = useRef(null); // Reference to the <img> element
  const cropperInstance = useRef(null); // Store Cropper instance for cleanup and cropping

  // Handle file upload and pass the file data to the parent
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        alert("File size exceeds 1 MB");
        return;
      }

      const fileUrl = URL.createObjectURL(file);
      setImageData(fileUrl); // Set the original image as the data URL
      setIsCropping(true); // Enable cropping when a new image is uploaded

      // Pass file data to the parent component using the provided callback function
      if (onFileUpload) {
        onFileUpload(file); // Pass the file object back to the parent component
      }
    }
  };

  // Handle cropping action
  const handleCrop = () => {
    if (cropperInstance.current) {
      const croppedCanvas = cropperInstance.current.getCroppedCanvas();
      setCroppedImage(croppedCanvas.toDataURL()); // Set cropped image URL
      setIsCropping(false); // Disable cropping UI after the crop
    }
  };

  // Reset the cropping UI when a new image is uploaded
  const handleReset = () => {
    setCroppedImage(null); // Clear cropped image
    setIsCropping(true); // Re-enable cropping
    setImageData(null); // Clear the uploaded image data
  };

  useEffect(() => {
    // Initialize cropper only when imageData is set
    if (imageData && imgRef.current) {
      if (square) {
        cropperInstance.current = new Cropper(imgRef.current, {
          aspectRatio: 1, // Set the aspect ratio for landscape cropping (16:9)
          viewMode: 1, // Contain image in canvas
          autoCropArea: 0.8, // Default 0.8 means the crop area will be 80% of the image
        });
      } else {
        cropperInstance.current = new Cropper(imgRef.current, {
          aspectRatio: 16 / 9, // Set the aspect ratio for landscape cropping (16:9)
          viewMode: 1, // Contain image in canvas
          autoCropArea: 0.8, // Default 0.8 means the crop area will be 80% of the image
        });
      }
    }

    // Cleanup when component unmounts or imageData changes
    return () => {
      // Revoke the object URL if it's not null
      if (imageData) {
        URL.revokeObjectURL(imageData); // Cleanup memory by revoking the object URL
      }

      // Cleanup Cropper instance
      if (cropperInstance.current) {
        cropperInstance.current.destroy(); // Destroy the cropper instance
      }
    };
  }, [imageData]);

  return (
    <div>
      <div className="mb-3">
        <label
          className="btn btn-outline-secondary col-md-12"
          style={{ textAlign: "center" }}
        >
          {title}
          <input
            type="file"
            name="photo"
            accept="image/jpeg, image/png, image/jpg"
            onChange={handleFileUpload}
            style={{ marginLeft: 60, marginTop: 30 }}
          />
        </label>
      </div>

      {imageData && isCropping && (
        <div>
          {/* Cropper UI visible when cropping is allowed */}
          <div ref={cropperRef}>
            <img
              ref={imgRef}
              src={imageData}
              alt="Upload"
              style={{
                width: `${predefinedWidth}px`,
                height: `${predefinedHeight}px`,
                objectFit: "cover",
              }}
            />
          </div>
          <div className="flex justify-center mt-2 rounded">
            <button
              className="bg-green-600 text-white p-2 rounded"
              onClick={handleCrop}
            >
              Crop Image
            </button>
          </div>
        </div>
      )}

      {croppedImage && !isCropping && (
        <div className="flex justify-center mt-5 mb-2">
          <img src={croppedImage} alt="Cropped" style={{ width: "50%" }} />
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
