import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Modal, message } from "antd";
import LoadingComponent from "../../Components/LoadingComponent.js";

import { Select } from "antd";
import FileUploadComponent from "../../Components/UploadCroppedImageComponent.js";
const Option = Select.Option;

const EvManufacturersScreen = () => {
  const [photo, setPhoto] = useState();
  const [manufacturerName, setManufacturerName] = useState();
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [manufacturers, setManufacturers] = useState();

  const [carName, setCarName] = useState();

  const [cars, setCars] = useState();

  const [addCarModalOpen, setAddCarModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState();
  const [updatedBatteryCapacities, setUpdatedBatteryCapacities] = useState([]);
  const [isEditCarModelOpen, setEditCarModelOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState();
  const [batteryCapacityForNewCar, setBatteryCapacityForNewCar] = useState();

  const [deleteCarModalOpen, setDeleteCarModalOpen] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);

  // Handle file upload from the child component
  const handleFileUpload = (file) => {
    setProfilePicData(file);
    // You can perform any actions like sending the file to a server here
  };
  // Handle file upload from the child component
  const handleFileUploadForUpdateEvImage = (file) => {
    setImage(file);
    // You can perform any actions like sending the file to a server here
  };

  const [profilePicData, setProfilePicData] = useState();
  const [image, setImage] = useState(null);
  const inputRef = useRef(null);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 bytes";

    const sizes = ["bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  };

  const handleAddCapacity = (newCapacity) => {
    newCapacity = newCapacity.trim(); // Remove unnecessary spaces
    const isDuplicate = updatedBatteryCapacities.some(
      (capacity) => String(capacity) === newCapacity
    );

    if (newCapacity && !isDuplicate) {
      setUpdatedBatteryCapacities((prevCapacities) => [
        ...prevCapacities,
        newCapacity,
      ]);
      // console.log("Updated Capacities:", updatedBatteryCapacities);
    } else {
      console.log("Duplicate or empty input:", newCapacity);
    }
  };
  const handleAdd = () => {
    const newCapacity = inputRef.current?.value.trim(); // Trim spaces to avoid empty values
    if (newCapacity) {
      handleAddCapacity(newCapacity); // Pass the value to the parent function
      inputRef.current.value = ""; // Clear input field
    }
  };

  useEffect(() => {
    selectedCar && setUpdatedBatteryCapacities(selectedCar?.batteryCapacity);
  }, [selectedCar]);

  const handleDeleteCapacity = (capacity) => {
    const filteredCapacities = updatedBatteryCapacities.filter(
      (item) => item !== capacity
    );
    setUpdatedBatteryCapacities(filteredCapacities);
  };

  const getCars = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/cars`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setCars(res?.data?.cars);
        setLoadingScreen(false);
      } else {
        setLoadingScreen(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingScreen(false);
    }
  };

  const getManufacturers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/manufacturers`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setManufacturers(res?.data?.manufacturers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCars();
    getManufacturers();
  }, []);

  const handleAddNewCar = async (e) => {
    e.preventDefault();
    try {
      if (
        !manufacturerName ||
        !carName ||
        !profilePicData ||
        !batteryCapacityForNewCar
      ) {
        return message.warning("All field required");
      }

      let images3Url = profilePicData;
      const ManufacturerData = new FormData();
      ManufacturerData.append("name", carName);
      ManufacturerData.append("manufacturer", manufacturerName);
      ManufacturerData.append("batteryCapacity", batteryCapacityForNewCar);

      if (profilePicData) {
        const data = {};
        ManufacturerData.forEach((value, key) => {
          data[key] = value;
        });
        // Step 2: Get the signed URL for uploading the profile picture
        const res = await axios.post(
          `${BaseUrl}/s3/generate/upload/url/for/ev`,
          {
            token: localStorage?.getItem("token"),
            CarDetails: data,
          }
        );

        const url = res?.data?.url;
        if (!url) {
          throw new Error("Failed to get signed URL for profile pic.");
        }

        // Step 3: Upload the profile picture to S3
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": profilePicData?.type || "application/octet-stream",
          },
          body: profilePicData,
        });
        if (!response.ok) {
          console.error("Profile picture upload failed:", response);
          message.warning("Forbidden from AWS");
          throw new Error("Failed to upload profile picture.");
        }

        // Step 4: Get the uploaded image URL without query string (to be used in the backend)
        images3Url = url.split("?")[0];
      }

      ManufacturerData.append("url", images3Url);

      // console.log(ManufacturerData);
      const formDataObject = {};
      ManufacturerData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      const res = await axios.post(`${BaseUrl}/add/new/car`, {
        token: localStorage?.getItem("token"),
        ManufacturerData: formDataObject,
      });

      if (res?.data?.success) {
        setAddCarModalOpen(false);
        getCars();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCar = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/delete/car`, {
        token: localStorage.getItem("token"),
        carId: selectedCar?._id,
      });
      if (res?.data?.success) {
        setEditCarModelOpen(false);
        setDeleteCarModalOpen(false);
        getCars();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCarDetails = async (e) => {
    e.preventDefault();
    const payload = {
      ...selectedCar,
      batteryCapacity: updatedBatteryCapacities,
      name: updatedName || selectedCar?.name,
    };

    let images3Url = null;
    if (image) {
      const data = selectedCar;
      // Step 2: Get the signed URL for uploading the profile picture
      const res = await axios.post(`${BaseUrl}/s3/generate/upload/url/for/ev`, {
        token: localStorage?.getItem("token"),
        CarDetails: data,
      });

      const url = res?.data?.url;
      if (!url) {
        throw new Error("Failed to get signed URL for profile pic.");
      }

      // Step 3: Upload the profile picture to S3
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": image?.type || "application/octet-stream",
        },
        body: image,
      });
      if (!response.ok) {
        console.error("Profile picture upload failed:", response);
        message.warning("Forbidden from AWS");
        throw new Error("Failed to upload profile picture.");
      }

      // Step 4: Get the uploaded image URL without query string (to be used in the backend)
      images3Url = url.split("?")[0];
    }

    const res = await axios.post(`${BaseUrl}/update/ev/battery/capacities`, {
      token: localStorage?.getItem("token"),
      evData: payload,
      uri: images3Url,
    });

    if (res?.data?.success) {
      setEditCarModelOpen(false);
      message.success(res?.data?.message);
      getCars();
    } else {
      message.error(res?.data?.message);
    }
  };

  return loadingScreen ? (
    <LoadingComponent />
  ) : (
    <>
      <Modal
        title="Add Ev Car"
        centered
        open={addCarModalOpen}
        onCancel={() => setAddCarModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={(e) => handleAddNewCar(e)}
          >
            Submit
          </button>,
        ]}
      >
        <div className="mb-4">
          <label className="btn btn-outline-secondary col-md-12">
            Select manufacturer
            <Select
              className="ml-4"
              showSearch
              style={{ width: 300 }}
              placeholder="Select manufacturer"
              optionFilterProp="children"
              onChange={(value) => setManufacturerName(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {manufacturers &&
                manufacturers?.map((manufacturer) => {
                  return (
                    <Option style={{ fontSize: 12 }} value={manufacturer?.name}>
                      {manufacturer?.name}
                    </Option>
                  );
                })}
            </Select>
          </label>
        </div>
        <label className="btn btn-outline-secondary col-md-12 mt-12">
          Car name
          <input
            type="text"
            required
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderRadius: 4,
              marginLeft: 84,
              padding: 4,
            }}
            onChange={(e) => setCarName(e.target.value)}
            // hidden
          />
        </label>

        <div className="mt-5">
          <label className="btn btn-outline-secondary col-md-12 mt-12">
            Battery Capacity
            <input
              type="numeric"
              required
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderRadius: 4,
                marginLeft: 84,
                padding: 4,
              }}
              onChange={(e) => setBatteryCapacityForNewCar(e.target.value)}
              // hidden
            />
          </label>
        </div>

        <div>
          <FileUploadComponent
            predefinedWidth={500}
            predefinedHeight={300}
            onFileUpload={handleFileUpload}
          />
          {uploadedFile && (
            <div>
              {/* <h3>Uploaded File Details:</h3>
              <p>Name: {uploadedFile.name}</p> */}
              <p>Size: {formatFileSize(uploadedFile.size)}</p>{" "}
              {/* Display file size in human-readable format */}
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title="Delete Ev Car"
        centered
        open={deleteCarModalOpen}
        onCancel={() => setDeleteCarModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              // backgroundColor: "#001529",
              // color: "white",
              padding: 10,
              borderRadius: 3,
              marginRight: 10,
              borderWidth: 1,
            }}
            className="btn btn-primary"
            onClick={() => setDeleteCarModalOpen(false)}
          >
            Cancel
          </button>,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={() => handleDeleteCar()}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure to delete {selectedCar?.name} car ?</p>
      </Modal>

      <Modal
        title="Edit EV Details"
        centered
        open={isEditCarModelOpen}
        onCancel={() => setEditCarModelOpen(false)}
        footer={[
          null,
          <button
            style={{
              padding: 10,
              borderRadius: 3,
            }}
            className="btn-secondary mx-5 mt-5 border"
            onClick={(e) => setDeleteCarModalOpen(true)}
          >
            Delete
          </button>,
          <button
            style={{
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary bg-blue-200"
            onClick={(e) => handleUpdateCarDetails(e)}
          >
            Update
          </button>,
        ]}
      >
        <div>
          <div className="relative mx-5 rounded-xl">
            <img
              alt={selectedCar?.name}
              src={`${selectedCar?.uri}`}
              style={{ height: 120, width: 130 }}
            />
            <div>
              <FileUploadComponent
                predefinedWidth={500}
                predefinedHeight={300}
                onFileUpload={handleFileUploadForUpdateEvImage}
                title={"Update Image"}
              />
              {uploadedFile && (
                <div>
                  {/* <h3>Uploaded File Details:</h3>
              <p>Name: {uploadedFile.name}</p> */}
                  <p>Size: {formatFileSize(uploadedFile.size)}</p>{" "}
                  {/* Display file size in human-readable format */}
                </div>
              )}
            </div>

            <div className="w-full flex mt-5 justify-center bg-blue-100 py-2 rounded">
              <p className="mt-2 mr-3">Ev name : </p>
              <input
                type="text"
                defaultValue={selectedCar?.name}
                placeholder="Enter EV Name"
                className="border rounded p-2 mr-2"
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </div>

            <div className="w-full">
              <p className="text-left mt-4 mb-2">Battery Capacities</p>
            </div>
            <div className="flex flex-wrap max-w-full">
              {updatedBatteryCapacities.map((capacity) => (
                <div
                  key={capacity}
                  className="border-0 px-2 py-1 mx-2 my-2 rounded-lg bg-blue-100 flex items-center"
                >
                  <p className="mr-2">{capacity} kWh</p>
                  <button
                    onClick={() => handleDeleteCapacity(capacity)}
                    className="text-red-500 font-bold"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
            <div className="mt-4 flex items-center">
              <input
                ref={inputRef}
                type="text"
                placeholder="Add new capacity (kWh)"
                className="border rounded p-2 mr-2 flex-grow"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAdd();
                  }
                }}
              />
              <button className="btn btn-primary" onClick={handleAdd}>
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ev Cars
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddCarModalOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add car
          </a>
        </span>
      </div>

      {/* Show All Manufacturers */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: 40,
        }}
      >
        {cars &&
          cars?.map((manufacturer, index) => {
            return (
              <div className="relative mx-5 px-20 py-10 my-10 rounded-xl shadow-xl md:w-[30%]">
                <i
                  onClick={() => {
                    setSelectedCar(manufacturer);
                    setEditCarModelOpen(true);
                    setImage(null);
                  }}
                  style={{ color: "gray" }}
                  class="fa-regular fa-pen-to-square absolute right-5"
                ></i>
                <img
                  alt={index}
                  src={`${manufacturer?.uri}`}
                  style={{ height: 120, width: 130 }}
                />
                <p className="text-center text-lg font-semibold">
                  {manufacturer?.manufacturer + " "}
                  {manufacturer?.name}
                </p>
                <div className="w-full">
                  <p className="text-left mt-4 mb-2">Battery Capacities</p>
                </div>
                <div className="flex flex-wrap max-w-full">
                  {manufacturer?.batteryCapacity?.map((capacity) => {
                    return (
                      <div
                        key={capacity}
                        className="border-0 px-2 py-1 mx-2 my-2 rounded-lg bg-blue-100"
                      >
                        <p>{capacity} kW</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default EvManufacturersScreen;
