import { Modal, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingComponent from "../../Components/LoadingComponent.js";
import { BaseUrl } from "../../static/BaseUrl.js";
import FileUploadComponent from "../../Components/UploadCroppedImageComponent.js";

const EvManufacturersScreen = () => {
  const [photo, setPhoto] = useState();
  const [profilePicData, setProfilePicData] = useState();

  const [manufacturerName, setManufacturerName] = useState();

  const [manufacturers, setManufacturers] = useState();

  const [loadingScreen, setLoadingScreen] = useState(true);

  const [addManufacturerModelOpen, setAddManufacturermodelOpen] =
    useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState();

  const [deleteManufacturerModalOpen, setDeleteManufacturerModalOpen] =
    useState(false);

  const getManufacturers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/manufacturers`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setManufacturers(res?.data?.manufacturers);
        setLoadingScreen(false);
      } else {
        setLoadingScreen(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingScreen(false);
    }
  };

  // Handle file upload from the child component
  const handleFileUpload = (file) => {
    setProfilePicData(file);
  };

  useEffect(() => {
    getManufacturers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingScreen(true);
    try {
      if (!manufacturerName || !profilePicData) {
        return message.warning("All field required");
      }

      let images3Url = profilePicData;
      const ManufacturerData = new FormData();
      ManufacturerData.append("manufacturer", manufacturerName);

      if (profilePicData) {
        const data = {};
        ManufacturerData.forEach((value, key) => {
          data[key] = value;
        });
        // Step 2: Get the signed URL for uploading the profile picture
        const res = await axios.post(
          `${BaseUrl}/s3/generate/upload/url/for/ev/manufacturer`,
          {
            token: localStorage?.getItem("token"),
            CarDetails: data,
          }
        );

        const url = res?.data?.url;
        if (!url) {
          throw new Error("Failed to get signed URL for Manufacturer pic.");
        }

        // Step 3: Upload the profile picture to S3
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": profilePicData?.type || "application/octet-stream",
          },
          body: profilePicData,
        });
        if (!response.ok) {
          console.error("Profile picture upload failed:", response);
          message.warning("Forbidden from AWS");
          throw new Error("Failed to upload profile picture.");
        }

        // Step 4: Get the uploaded image URL without query string (to be used in the backend)
        images3Url = url.split("?")[0];
      }

      ManufacturerData.append("url", images3Url);

      // console.log(ManufacturerData);
      const formDataObject = {};
      ManufacturerData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      const res = await axios.post(`${BaseUrl}/add/new/manufacturer`, {
        token: localStorage?.getItem("token"),
        ManufacturerData: formDataObject,
      });

      if (res?.data?.success) {
        setAddManufacturermodelOpen(false);
        getManufacturers();
        message.success(res?.data?.message);
      }
      setLoadingScreen(false);
    } catch (error) {
      console.log(error);
      setLoadingScreen(false);
    }
  };

  const handleDeleteManufacturer = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/delete/manufacturer`, {
        token: localStorage.getItem("token"),
        manufacturerId: selectedManufacturer?._id,
      });
      if (res?.data?.success) {
        setDeleteManufacturerModalOpen(false);
        getManufacturers();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return loadingScreen ? (
    <LoadingComponent />
  ) : (
    <>
      <Modal
        title="Add Ev Manufacturer"
        centered
        open={addManufacturerModelOpen}
        onCancel={() => setAddManufacturermodelOpen(false)}
        footer={[
          null,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>,
        ]}
      >
        <label className="btn btn-outline-secondary col-md-12">
          Manufacturer name
          <input
            type="text"
            required
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderRadius: 4,
              marginLeft: 10,
              padding: 4,
            }}
            onChange={(e) => setManufacturerName(e.target.value)}
            // hidden
          />
        </label>

        <FileUploadComponent onFileUpload={handleFileUpload} square={true} />
      </Modal>

      <Modal
        title="Delete Ev Manufacturer"
        centered
        open={deleteManufacturerModalOpen}
        onCancel={() => setDeleteManufacturerModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              // backgroundColor: "#001529",
              // color: "white",
              padding: 10,
              borderRadius: 3,
              marginRight: 10,
              borderWidth: 1,
            }}
            className="btn btn-primary"
            onClick={() => setDeleteManufacturerModalOpen(false)}
          >
            Cancel
          </button>,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={() => handleDeleteManufacturer()}
          >
            Delete
          </button>,
        ]}
      >
        <p>
          Are you sure to delete {selectedManufacturer?.name} manufacturer ?
        </p>
      </Modal>

      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ev Manufacturer
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddManufacturermodelOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add manufacturer
          </a>
        </span>
      </div>

      {/* Show All Manufacturers */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: 40,
        }}
      >
        {manufacturers &&
          manufacturers?.map((manufacturer, index) => {
            return (
              <div className="mx-5 px-20 py-10 my-10 relative rounded-xl shadow-xl">
                <i
                  onClick={() => {
                    setSelectedManufacturer(manufacturer);
                    setDeleteManufacturerModalOpen(true);
                    setProfilePicData(null);
                  }}
                  style={{ color: "gray" }}
                  className="fa-solid fa-trash-can absolute right-5"
                ></i>
                <img
                  alt={index}
                  src={`${manufacturer?.uri}`}
                  style={{ height: 120, width: 130 }}
                />
                <p className="text-center text-lg font-semibold">
                  {manufacturer?.name?.toUpperCase()}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default EvManufacturersScreen;
