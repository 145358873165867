import { message, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../static/BaseUrl";
import LoadingComponent from "../../Components/LoadingComponent";

const UploadApplicationImagesScreen = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [profilePicData, setProfilePicData] = useState();
  const [profilepicLink, setProfilePicLink] = useState();
  const [fileDimensions, setFileDimensions] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const [preLoadedData, setPreLoadedData] = useState();

  // Handle File Upload
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      if (file.size > 1 * 1024 * 1024) {
        alert(`File size exceeds ${1} MB limit.`);
        return;
      }

      // Handle images
      if (file.type.startsWith("image/")) {
        setProfilePicData(file);
        setProfilePicLink(URL.createObjectURL(file));
      }
    });
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!profilePicData) {
        return message.warning("All field required");
      }

      let images3Url = profilePicData;

      if (profilePicData) {
        // Step 2: Get the signed URL for uploading the profile picture
        const res = await axios.post(
          `${BaseUrl}/s3/generate/upload/url/for/appImage`,
          {
            token: localStorage?.getItem("token"),
            type: selectedImage,
          }
        );

        const url = res?.data?.url;
        if (!url) {
          throw new Error("Failed to get signed URL for profile pic.");
        }

        // Step 3: Upload the profile picture to S3
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": profilePicData?.type || "application/octet-stream",
          },
          body: profilePicData,
        });
        if (!response.ok) {
          console.error("Profile picture upload failed:", response);
          message.warning("Forbidden from AWS");
          throw new Error("Failed to upload profile picture.");
        }

        // Step 4: Get the uploaded image URL without query string (to be used in the backend)
        images3Url = url.split("?")[0];
      }

      console.log(images3Url);

      const res = await axios.post(
        `${BaseUrl}/upload/app/asset/for/application`,
        {
          token: localStorage?.getItem("token"),
          image: selectedImage,
          url: images3Url,
        }
      );

      if (res?.data?.success) {
        message.success(res?.data?.message);
      } else {
        message.error(res?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.warning("Something went wrong...");
    }
  };

  useEffect(() => {
    if (selectedImage === "greetings") {
      return setFileDimensions("Recommended Dimensions: 467x700px");
    } else if (selectedImage === "mapPin") {
      return setFileDimensions("Recommended Dimensions: 96x96px");
    } else if (selectedImage === "chargeVehicle") {
      return setFileDimensions("Recommended Dimensions: 1600x420px");
    }
  }, [selectedImage]);

  useEffect(() => {
    const getPreLoadedData = async () => {
      try {
        const res = await axios.post(`${BaseUrl}/get/application/assets`);
        if (res?.data?.success) {
          setPreLoadedData(res?.data?.data);
          setIsOn(res?.data?.data?.isGreetingsModelOpen);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    getPreLoadedData(); // Call the async function inside useEffect
  }, []);

  const handleUpdateGreetingModelStatus = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BaseUrl}/update/greetings/modal/visibility`,
        {
          token: localStorage?.getItem("token"),
          isGreetingsModelOpen: !isOn,
        }
      );
      if (res?.data?.success) {
        setLoading(false);
        message.success("Status Updated...");
        setIsOn(res?.data?.currentStatus);
      } else {
        message.warning("Soomething went wrong...");
      }
    } catch (error) {
      console.log(error);
      message.warning("Soomething went wrong...");
      setLoading(false);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div className="w-full flex flex-col  justify-center sm:justify-start">
      <p style={{ marginTop: 4 }} className="font-bold p-lg p-center">
        Upload App Images
      </p>

      <div className="flex flex-col justify-center w-full items-center mt-2">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={selectedImage || "Select Image Type"}
          onChange={(value) => setSelectedImage(value)}
          style={{ width: 300, marginLeft: 30, marginTop: 5, marginBottom: 20 }}
        >
          <option value={"greetings"}>Greetings Image</option>
          <option value={"mapPin"}>HomeScreen Map Pin Image</option>
          <option value={"chargeVehicle"}>
            Charge Vehicle Screen Top Image
          </option>
        </Select>

        {selectedImage && (
          <div className="flex flex-col justify-center items-center">
            <p className="font-bold  text-2xl ">Upload Image</p>
            <p className="mt-2">
              Allowed Image Format : JPG, PNG | Max size: 1MB
            </p>

            <p className="mt-2"> {fileDimensions}</p>

            {selectedImage === "greetings" && (
              <div className="flex justify-center items-start">
                <p className="mx-5 mt-5">Greeting Modal Visibility : </p>
                <button
                  onClick={() => handleUpdateGreetingModelStatus()}
                  style={{
                    backgroundColor: isOn ? "green" : "red",
                    color: "white",
                    padding: "5px 20px",
                    fontSize: "16px",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    marginTop: 15,
                  }}
                >
                  {isOn ? "ON" : "OFF"}
                </button>
              </div>
            )}

            <div className="mb-3 ml-28 flex flex-row">
              <div>
                <label
                  className="btn btn-outline-secondary"
                  style={{ textAlign: "center" }}
                >
                  {/* {photo ? photo.name : "Upload Photo"} */}
                  Upload Photo
                  <input
                    type="file"
                    name="photo"
                    accept="image/jpeg, image/png"
                    onChange={handleFileUpload}
                    style={{ marginLeft: 10, marginTop: 30 }}
                    // hidden
                  />
                </label>
              </div>
            </div>

            <div className="mb-3 flex flex-row justify-center items-center">
              {selectedImage && (
                <div className="text-center justify-center mt-20  overflow-hidden mx-10">
                  <p>Current</p>
                  <img
                    src={
                      selectedImage === "greetings"
                        ? preLoadedData?.greetingModelImageUrl
                        : selectedImage === "mapPin"
                        ? preLoadedData?.mapPinImageUrl
                        : preLoadedData?.applicationChargeVehicleImageUrl
                    }
                    alt="img"
                    className={`img img-responsive border rounded-md ${
                      selectedImage === "greetings"
                        ? "h-60"
                        : selectedImage === "mapPin"
                        ? "h-10"
                        : "h-24 w-auto"
                    } `}
                  />
                </div>
              )}
              {profilePicData && (
                <div className="text-center justify-center mt-20  overflow-hidden mx-10 ">
                  <p>New</p>
                  <img
                    src={profilepicLink}
                    alt="product_photo"
                    className={`img img-responsive border rounded-md ${
                      selectedImage === "greetings"
                        ? "h-60 w-auto"
                        : selectedImage === "mapPin"
                        ? "h-10 w-auto"
                        : "h-24 w-auto"
                    } `}
                  />
                </div>
              )}
            </div>

            {profilePicData && (
              <button
                style={{
                  backgroundColor: "#001529",
                  color: "white",
                  padding: 10,
                  borderRadius: 3,
                  paddingRight: 26,
                  paddingLeft: 26,
                }}
                className="btn btn-primary mt-10"
                onClick={(e) => handleUploadImage(e)}
              >
                Upload
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadApplicationImagesScreen;
